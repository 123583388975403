import React from 'react'
import Layout from '../components-en/layout'
import Head from '../components-en/head'
import how1 from "../images/en/how_1.png"
import how2 from "../images/how_2.png"
import how3 from "../images/how_3.svg"
import how4 from "../images/how_4.svg"
import highlight1 from "../images/en/ne_icin_biriktiriyorsun.svg"
import highlight2 from "../images/en/hep_beraber_biriktirin.svg"
import highlight3 from "../images/en/hayallerini_finanse_et.svg"
import highlight4 from "../images/en/beklemede_kal.svg"

import '../styles/grid.scss'
import '../styles/index.scss'


const HowPage = () => {
  return(
    <Layout>
      <Head title="How it works?" description="Choose your monthly savings goal, how many people you want to save money with and also payment day. You can share your motivation of saving with other circle members." />
      <div className="row section-title">
        <h1>How can you save money?</h1>
      </div>
      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Create a circle.</h1>
                <p> To start saving money, create your first circle and name it. Choose your monthly savings goal, how many people you want to save money with and also payment day. You can share your motivation of saving with other circle members.</p>
                <img className="highlight-image" src={highlight1} alt="What are you saving for?" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={how1} alt="Create a circle"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img src={how2} alt="Invite your friends or family members."/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1>Invite your friends or family members.</h1>
                <p>You can invite your friends, family members who share the same dream with you. You can invite as many people as you want. The more crowded the circle, the less you pay. </p>
                <img className="highlight-image" src={highlight2} alt="Save together" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>Start saving.</h1>
                <p>Make your payments every month, take total amount when it’s your turn. Don't forget to share your joy with the circle members! </p>
                <img className="highlight-image" src={highlight3} alt="Finance your dreams!" />
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={how3} alt="Start saving"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>

      <div className="row colored light-blue-bg">
       <div className="hero-container">
        <div className="container">
          <div className="row">
          <div className="col-6 center">
              <img src={how4} alt="Join circle"/> 
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h1>Join circle</h1>
                <p>People who have the same dream as you may created a circle before. Check what has been created before, join the one thats suits you best!  </p>
                <img className="highlight-image" src={highlight4} alt="Soon" />
              </div>
            </div>
            
          </div>
        </div>  
      </div>
      </div>

      <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-text center">
                <h1>What are you saving for?</h1>
                <div className="hayaller center">
                    <button>Playstation 5  
                      <span role="img" aria-label="emoji"  className="emoji">
                        🔫
                      </span>
                    </button>
                    <button>Audi 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🚙
                      </span>
                    </button>
                    <button>Paris Holidy 
                      <span role="img" aria-label="emoji"  className="emoji">
                      💭
                      </span>
                    </button>
                    <button>Airpods 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎵
                      </span>
                    </button>
                    <button>Master  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🎓
                      </span>
                    </button>
                    <button>Electric Scooter  
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛴 
                      </span>
                    </button>
                    <button>iPhone 
                      <span role="img" aria-label="emoji"  className="emoji">
                      📱 
                      </span>
                    </button>
                    <button>Shopping 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🛒 
                      </span>
                    </button>
                    <button>World Tour 
                      <span role="img" aria-label="emoji"  className="emoji">
                      🌎 
                      </span>
                    </button>
                    <button>Avocado Garden
                      <span role="img" aria-label="emoji"  className="emoji">
                      🥑
                      </span>
                    </button>
                    <button>Wedding
                      <span role="img" aria-label="emoji" className="emoji">
                      👰🤵
                      </span>
                    </button>

                    

                </div>
              </div>
            </div>
          </div>
        </div>  
      </div>
      </div>

    </Layout>
  )
}

export default HowPage